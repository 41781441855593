require('./bootstrap');

import Splide from '@splidejs/splide';
import { nodeName } from 'jquery';

var splide = new Splide( '.splide', {
	type   : 'fade',
	perPage: 1,
    padding: 0,
    rewind: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    pagination: false,
    interval: 3000
} );

splide.mount();


